<template>
  <!-- (监管)培训管理 - 学员认证信息 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员认证信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="学员信息" class="searchboxItem ci-full">
              <span class="itemLabel">学员信息:</span>
              <el-select
                v-model="retrievalData.userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">班级名称:</span>
              <el-input
                v-model="retrievalData.projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">班级编码:</span>
              <el-input
                v-model="retrievalData.projectCode"
                clearable
                placeholder="请输入班级编码"
                size="small"
              ></el-input>
            </div>
          </div>
           <div class="searchbox" style="margin-bottom: 5px">
              <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" >机构名称:</span>
              <el-select
                size="small"
                v-model="retrievalData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                @change="bindChange"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="有无证件照片" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">有无证件照片:</span>
              <el-select
                size="small"
                v-model="retrievalData.hasIdPhoto"
                filterable
                clearable
                placeholder="请选择有无证件照片"
              >
                <el-option
                  v-for="item in hasIdPhotoSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
             <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">认证状态:</span>
              <el-select
                clearable
                size="small"
                v-model="retrievalData.authentication"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in authenticationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
           </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              /><el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
              />
              <el-table-column
                label="联系电话"
                align="left"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="100"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="100"
              />
              <el-table-column
                label="认证状态"
                align="left"
                show-overflow-tooltip
                prop="authentication"
                ><template slot-scope="scope">
                  {{
                    $setDictionary("AUTHENTICATION", scope.row.authentication)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="有无证件照片"
                align="left"
                show-overflow-tooltip
                prop="hasIdPhoto"
                >
                <template slot-scope="scope">
                  {{
                    scope.row.hasIdPhoto ? '有':'无'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" show-overflow-tooltip
                ><template slot-scope="scope">
                  <el-button
                    style="padding:0 5px"
                    type="text"
                    size="mini"
                    @click="seeStudentAuthentication(scope.row)"
                    >查看学员信息</el-button
                  >
                </template>
              </el-table-column>
              <!-- <el-table-column label="认证照片" align="center">
                <template slot-scope="scope">
                  <el-image
                    style="width: 40px; height: 30px"
                    :src="scope.row.headPath"
                    :preview-src-list="[scope.row.headPath]"
                  >
                    <div slot="error" class="image-slot">
                      {{'--'}}
                    </div>
                  </el-image>
                </template>
              </el-table-column> -->
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog title="查看学员信息" :visible.sync="dialogVisible" width="50%">
      <div class="ovy-a" style="height:600px">
        <el-form ref="ruleForm" label-width="200px" class="el_form">
          <el-form-item label="姓名:" class="form-inline">
            <p>{{ StudentAuthenticationInformation.userName }}</p>
          </el-form-item>
          <el-form-item label="身份证号:" class="form-inline">
            <p>{{ StudentAuthenticationInformation.idcard }}</p>
          </el-form-item>
           <el-form-item label="学员类型:" class="form-inline">
            <p>
              {{
                $setDictionary(
                  "GS_USER_TYPE",
                  StudentAuthenticationInformation.gsUserType
                ) || '无'
              }}
            </p>
          </el-form-item>
          <el-form-item label="户口类型:" class="form-inline">
            <p>
              {{
                $setDictionary(
                  "GS_REGISTERED_PERMANENT_RESIDENCE",
                  StudentAuthenticationInformation.gsHouseholdType
                ) || '无'
              }}
            </p>
          </el-form-item>
          <el-form-item label="身份证照片:">
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              :disabled="true"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="
                  StudentAuthenticationInformation.idCardFront ||
                    require('@/assets/develop.png')
                "
                 :preview-src-list="[ StudentAuthenticationInformation.idCardFront]"
                fit="contain"
              ></el-image>
            </el-upload>
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              :disabled="true"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="
                  StudentAuthenticationInformation.idCardBack ||
                    require('@/assets/develop.png')
                "
                 :preview-src-list="[ StudentAuthenticationInformation.idCardBack]"
                fit="contain"
              ></el-image>
            </el-upload>
          </el-form-item>
          <el-form-item label="认证照片:">
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              :disabled="true"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="
                  StudentAuthenticationInformation.headPath ||
                    require('@/assets/develop.png')
                "
                 :preview-src-list="[StudentAuthenticationInformation.headPath]"
                fit="contain"
              ></el-image>
            </el-upload>
          </el-form-item>
          <el-form-item
            :label="getValName(item.fileType) + ':'"
            v-for="(item,
            index) in StudentAuthenticationInformation.eduUserCredentials"
            :key="index"
          >
            <el-upload
              :on-change="
                (file, fileList) => {
                  handlebusinessLicense2(file, item.fileType, index);
                }
              "
              :before-upload="$beforeAvatarUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              :disabled="true"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="item.fileKey || require('@/assets/develop.png')"
                 :preview-src-list="[item.fileKey]"
                fit="contain"
                
              ></el-image>
            </el-upload>
          </el-form-item>
            <el-form-item label="证件照片:" v-if="StudentAuthenticationInformation.idPhotoPath">
            <el-upload
              :before-upload="$beforeAvatarUpload"
              :http-request="$requestMine"
              :show-file-list="false"
              :disabled="true"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
            >
              <el-image
                :src="
                  StudentAuthenticationInformation.idPhotoPath ||
                    require('@/assets/develop.png')
                "
                 :preview-src-list="[StudentAuthenticationInformation.idPhotoPath]"
                fit="contain"
              ></el-image>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "SupervisionEnd/studentCertificationInformation",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      retrievalData: {
        // 单位名称
        userId: "",
        // 班级名称
        projectName: "",
        // 机构名称
        compId: "",
        // 班级编码
        projectCode: "",
        //有无证件照片
        hasIdPhoto:'',
        //认证
        authentication:'',
      },
      // 机构名称 - 下拉数据
      CompanyList: [],
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      dialogVisible: false, //学员信息弹窗
      StudentAuthenticationInformation: {}, //学员信息
      hasIdPhotoSelect:[
        {
          value:true,
          label:'有'
        },
        {
          value:false,
          label:'无'
        }
      ],
      authenticationList:[]
    };
  },
  watch: {
    "retrievalData.userId": function() {
      this.superPorjectSelect();
    },
  },
  created() {
    this.superUserSelect();
    this.getAuthenticationList()
  },
  mounted() {},
  methods: {
    // 获取学员信息 - 下拉数据
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    // 三个字搜索 - 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取学员信息 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
        // 单位名称
        userId: this.retrievalData.userId,
        // 班级名称
        projectName: this.retrievalData.projectName,
        // 班级编码
        projectCode: this.retrievalData.projectCode,
        // 机构名称
        compId: this.retrievalData.compId,
        // 有无证件照片
        hasIdPhoto: this.retrievalData.hasIdPhoto,
        // 是否认证
        authentication: this.retrievalData.authentication,
      };
      this.doFetch({
        url: "/gov/project/v1/auth/user/page",
        params,
        pageNum,
      });
    },
    seeStudentAuthentication(row) {
      this.dialogVisible = true;
      this.$post("/gov/project/v1/auth/user/info", {
        projectId: row.projectId,
        userId: row.userId,
      }).then((ret) => {
        this.StudentAuthenticationInformation = ret.data;
      });
    },
    getValName(fileType) {
      return this.$setDictionary("CREDENTIAL_TYPE", fileType);
    },
    getAuthenticationList() {
      let authenticationList = this.$setDictionary('AUTHENTICATION','list')
      for(const key in authenticationList)
        this.authenticationList.push({
          value:key,
          label:authenticationList[key]
        })
    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.el_form {
  .form-inline {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
}
.el-image {
  width: 100%;
  img {
    display: block;
    margin:0 auto;
  }
}
</style>
